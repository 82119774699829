class MNavigationPopoverSystem {
	#isOpen = false;

	#fadeOutAnimation;

	#isMouseOver = false;

	#duration = 0;

	#sourceElement = null;

	constructor(element) {
		this.element = element;
		this.headerBackdropElement = document.querySelector('.o-header__backdrop');
		this.containerElement = element.querySelector('.m-navigation-flyout__container');
		this.listItemElements = element.querySelectorAll('.m-navigation-flyout__list > li');

		let duration = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--duration-medium'));
		duration = Number.isFinite(duration) ? duration : 200;
		this.#duration = duration;

		const { id } = element;
		let mouseleaveTimeout;

		const sourceElements = document.querySelectorAll(`[data-popovertarget=${id}]`);

		this.#sourceElement = document.querySelector(`.m-navigation__list > li[data-popovertarget=${id}]`);

		[...sourceElements].forEach((sourceElement) => {
			if (sourceElement.tagName === 'BUTTON') {
				sourceElement.addEventListener('click', (event) => {
					event.preventDefault();
					if (this.#isOpen) {
						this.hide();
					} else {
						this.show();
					}
				});
			} else {
				sourceElement.addEventListener('mouseenter', () => {
					setTimeout(() => {
						if (this.#isMouseOver) {
							this.show();
						}
					}, 300);
					clearTimeout(mouseleaveTimeout);
					this.#isMouseOver = true;
				});
				sourceElement.addEventListener('mouseleave', () => {
					mouseleaveTimeout = setTimeout(() => {
						this.hide();
					}, 400);
					this.#isMouseOver = false;
				});
			}
		});

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				this.hide();
			}
		});

		this.element.addEventListener('toggle', (event) => {
			if (event.newState === 'closed') {
				this.hide();
			} else {
				this.show();
			}
		});
	}

	get insetBlockStart() {
		return this.#sourceElement.offsetTop + this.#sourceElement.offsetHeight;
	}

	show() {
		if (this.#isOpen === false && window.matchMedia('(min-width: 61em)').matches) {
			this.#isOpen = true;

			// hide others
			document.aLangSwitch?.hide();
			document.mSearch?.hide();

			this.element.toggleAttribute('data-show-popover', true);
			this.element.style.insetBlockStart = `${this.insetBlockStart}px`;

			this.containerElement.animate([
				{ clipPath: 'inset(0 0 100%)' },
				{ clipPath: 'inset(0)' },
			], {
				duration: this.#duration,
			});

			[...this.listItemElements].forEach((element, index) => {
				element.animate([
					{ opacity: '0', translate: '0 calc(-5rem / 10)' },
					{ opacity: '1', translate: '0 0' },
				], {
					delay:
						(this.#duration / (this.listItemElements.length * 0.8))
						* index + this.#duration / 2,
					duration: this.#duration,
					fill: 'both',
				});
			});

			// wait a frame. other popovers could remove backdrop via hide() method.
			requestAnimationFrame(() => {
				this.headerBackdropElement.toggleAttribute('data-visible', true);
			});
		}
	}

	hide() {
		if (this.#isOpen === true && this.#fadeOutAnimation?.playState !== 'running' && window.matchMedia('(min-width: 61em)').matches) {
			this.#fadeOutAnimation = this.containerElement.animate([
				{ clipPath: 'inset(0)' },
				{ clipPath: 'inset(0 0 100%)' },
			], {
				duration: this.#duration * 1.5,
			});

			[...this.listItemElements].reverse().forEach((element, index) => {
				element.animate([
					{ opacity: '1', translate: '0 0' },
					{ opacity: '0', translate: '0 calc(-5rem / 10)' },
				], {
					delay: (this.#duration / (this.listItemElements.length)) * index,
					duration: this.#duration,
					fill: 'forwards',
				});
			});

			this.headerBackdropElement.toggleAttribute('data-visible', false);

			this.#fadeOutAnimation.addEventListener('finish', () => {
				this.element.toggleAttribute('data-show-popover', false);
				this.#isOpen = false;
			});
		}
	}
}

[...document.querySelectorAll('.m-navigation-flyout')].forEach((_) => new MNavigationPopoverSystem(_));
