import {
	LitElement,
	html,
} from 'lit';

/** @typedef {'top'|'top-start'|'top-end'|'right'|'right-start'|'right-end'|
'bottom'|'bottom-start'|'bottom-end'|'left'|'left-start'|'left-end'} Placement */

class ViTooltip extends LitElement {
	#targetElement;

	static properties = {
		placement: { type: String }, /** @type Placement */
		_position: {
			type: Object,
			attribute: false,
		},
	};

	connectedCallback() {
		super.connectedCallback();
		const { id } = this;

		this.toggleAttribute('popover', true);
		/** hidden is no longer required */
		this.toggleAttribute('hidden', false);

		this.#targetElement = this.getRootNode().querySelector(`[data-tooltip-target="${id}"]`);
		if (this.#targetElement) {
			this.#targetElement.setAttribute('aria-labelledby', id);

			// Don’t activate events for touch devices.
			if (window.matchMedia('(hover: hover').matches) {
				this.#targetElement.addEventListener('focus', this.show.bind(this));
				this.#targetElement.addEventListener('blur', this.hide.bind(this));
				this.#targetElement.addEventListener('mouseenter', this.show.bind(this));
				this.#targetElement.addEventListener('mouseleave', this.hide.bind(this));
			}
		}
	}

	onScroll() {
		this.updatePosition();
	}

	updatePosition() {
		const {
			x,
			y,
			width,
			height,
		} = this.#targetElement.getBoundingClientRect();

		this.style.setProperty('--x', x + width * 0.5);
		if (this.placement === 'bottom') {
			this.style.setProperty('--y', y + height);
		} else {
			this.style.setProperty('--y', y);
		}
	}

	show() {
		/** Prevent showPopover, when button is pressed (a-lang-switch) */
		if (this.#targetElement && this.#targetElement.getAttribute('aria-pressed') !== 'true') {
			this.showPopover();
			document.addEventListener('scroll', this.onScroll.bind(this), {
				passive: true,
			});
			this.updatePosition();
		}
	}

	hide() {
		document.removeEventListener('scroll', this.onScroll);
		this.hidePopover();
	}

	toggle() {
		if (this.hasAttribute('hidden')) {
			this.show();
		} else {
			this.hide();
		}
	}

	render() {
		return html`
			<link rel="stylesheet" href="/assets/css/vi-tooltip.${BUILT_AT}.css">
			<div class="container">
				<slot></slot>
				<span class="arrow"></span>
			</div>
		`;
	}
}

/**
 * Define the custom element
 * popover api is required
 */
if (!customElements.get('vi-tooltip') && HTMLElement.prototype.hasOwnProperty('popover')) {
	customElements.define('vi-tooltip', ViTooltip);
}
