class ALangSwitch {
	#isOpen = false;

	#fadeOutAnimation;

	#duration = 0;

	constructor(element) {
		this.element = element;
		this.headerBackdropElement = document.querySelector('.o-header__backdrop');
		this.anchorElement = document.getElementById(element.getAttribute('data-anchor'));
		this.linkElements = element.querySelectorAll('a');

		let duration = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--duration-medium'));
		duration = Number.isFinite(duration) ? duration : 0;
		this.#duration = duration;

		window.addEventListener('resize', this.updateCssVariables.bind(this));
		this.anchorElement.addEventListener('click', (event) => {
			event.preventDefault();
			if (this.#isOpen) {
				this.hide();
			} else {
				this.show();
			}
		});

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				this.hide();
			}
		});
		document.addEventListener('click', (event) => {
			if (event.target.closest(`.${this.anchorElement.className}`) !== this.anchorElement) {
				this.hide();
			}
		});

		this.element.addEventListener('toggle', (event) => {
			if (event.newState === 'closed') {
				this.hide();
			} else {
				this.show();
			}
		});
	}

	updateCssVariables() {
		const inlineEnd = document.body.offsetWidth - this.anchorElement.getBoundingClientRect().right;
		this.element.style.setProperty('--_inset-inline-end', `${inlineEnd}px`);
	}

	show() {
		if (this.#isOpen === false) {
			this.#isOpen = true;

			// hide others
			document.mSearch?.hide();

			this.element.toggleAttribute('data-show-popover', true);
			this.anchorElement.setAttribute('aria-pressed', 'true');
			this.updateCssVariables();

			this.element.animate([
				{ clipPath: 'inset(0 0 100%)' },
				{ clipPath: 'inset(0)' },
			], {
				duration: this.#duration,
			});

			[...this.linkElements].forEach((linkElement, index) => {
				linkElement.animate([
					{ marginBlockStart: '-0.5rem', opacity: 0 },
					{ marginBlockStart: 0, opacity: 1 },
				], {
					duration: this.#duration,
					delay:
						(this.#duration / (this.linkElements.length * 0.8))
						* index,
					fill: 'both',
				});
			});

			// wait a frame. other popovers could remove backdrop via hide() method.
			requestAnimationFrame(() => {
				this.headerBackdropElement.toggleAttribute('data-visible', true);
			});
		}
	}

	hide() {
		if (this.#isOpen === true && this.#fadeOutAnimation?.playState !== 'running') {
			this.#fadeOutAnimation = this.element.animate([
				{ clipPath: 'inset(0)' },
				{ clipPath: 'inset(0 0 100%)' },
			], {
				duration: this.#duration * 1.5,
			});

			[...this.linkElements].reverse().forEach((linkElement, index) => {
				linkElement.animate([
					{ marginBlockStart: 0, opacity: 1 },
					{ marginBlockStart: '-0.5rem', opacity: 0 },
				], {
					delay:
						(this.#duration / (this.linkElements.length)) * index,
					duration: this.#duration,
					fill: 'both',
				});
			});

			this.headerBackdropElement.toggleAttribute('data-visible', false);

			this.#fadeOutAnimation.addEventListener('finish', () => {
				this.element.toggleAttribute('data-show-popover', false);
				this.anchorElement.setAttribute('aria-pressed', 'false');
				this.#isOpen = false;
			});
		}
	}
}

const element = document.querySelector('.a-lang-switch');
if (element) {
	document.aLangSwitch = new ALangSwitch(element);
}
