class MNavigation {
	#isOpen = false;

	#duration = 0;

	#fadeOutAnimation;

	constructor(element) {
		this.element = element;
		this.headerBackdropElement = document.querySelector('.o-header__backdrop');
		this.containerElement = element.querySelector('.m-navigation__container');
		this.listItemElements = element.querySelectorAll('.m-navigation__list > li:not(.m-navigation__switch-language)');
		this.switchLanguageElement = element.querySelector('.m-navigation__switch-language');
		this.elementsToInert = document.querySelectorAll('body > *:not(.o-header)');
		this.buttonHamburgerElement = element.querySelector('.a-button[data-name="hamburger"]');

		let duration = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--duration-medium'));
		duration = Number.isFinite(duration) ? duration : 200;
		this.#duration = duration;

		const onResize = () => {
			const isOffcanvasNavigation = window.matchMedia('not (min-width: 61em)').matches;
			if (isOffcanvasNavigation) {
				this.containerElement.setAttribute('role', 'dialog');
				this.containerElement.setAttribute('aria-modal', 'true');
				this.containerElement.setAttribute('tabindex', '1');
				if (this.#isOpen === false) {
					this.containerElement.toggleAttribute('data-open', false);
				}
			} else {
				this.containerElement.removeAttribute('role');
				this.containerElement.removeAttribute('aria-modal');
				this.containerElement.removeAttribute('tabindex');
				this.containerElement.toggleAttribute('data-open', false);
				if (this.#isOpen) {
					this.hide(true);
				}
			}
		};

		this.buttonHamburgerElement.addEventListener('click', (event) => {
			event.preventDefault();
			if (this.#isOpen) {
				this.hide();
			} else {
				this.show();
			}
		});

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				this.hide();
			}
		});

		this.headerBackdropElement.addEventListener('click', (event) => {
			event.preventDefault();
			this.hide();
		});

		window.addEventListener('resize', onResize);

		onResize();
	}

	show() {
		if (this.#isOpen === false) {
			this.#isOpen = true;

			document.mSearch?.hide();

			this.buttonHamburgerElement.toggleAttribute('data-active', true);
			this.containerElement.toggleAttribute('data-open', true);
			document.scrollingElement.toggleAttribute('data-no-scroll', true);

			this.containerElement.focus();

			this.containerElement.animate([
				{ clipPath: 'inset(0 0 100%)' },
				{ clipPath: 'inset(0)' },
			], {
				duration: this.#duration,
			});

			[...this.listItemElements].forEach((element, index) => {
				element.animate([
					{ opacity: '0', translate: 'calc(-5rem / 10) 0' },
					{ opacity: '1', translate: '0 0' },
				], {
					delay:
						(this.#duration / (this.listItemElements.length * 0.8))
						* index + this.#duration / 2,
					duration: this.#duration,
					fill: 'both',
				});
			});

			this.switchLanguageElement.animate([
				{ opacity: '0', translate: '0 calc(5rem / 10)' },
				{ opacity: '1', translate: '0 0' },
			], {
				delay:
					(this.#duration * 0.8) + this.#duration / 2,
				duration: this.#duration,
				fill: 'both',
			});

			// wait a frame. other popovers could remove backdrop via hide() method.
			requestAnimationFrame(() => {
				this.headerBackdropElement.toggleAttribute('data-visible', true);
			});

			[...this.elementsToInert].forEach((_) => _.toggleAttribute('inert', true));
		}
	}

	hide(instant = false) {
		if (this.#isOpen === true && this.#fadeOutAnimation?.playState !== 'running') {
			const duration = instant === true ? 0 : this.#duration;
			document.scrollingElement.toggleAttribute('data-no-scroll', false);

			this.#fadeOutAnimation = this.containerElement.animate([
				{ clipPath: 'inset(0)' },
				{ clipPath: 'inset(0 0 100%)' },
			], {
				duration,
			});

			if (instant === false) {
				[...this.listItemElements].reverse().forEach((element, index) => {
					element.animate([
						{ opacity: '1', translate: '0 0' },
						{ opacity: '0', translate: 'calc(-5rem / 10) 0' },
					], {
						delay: (duration / (this.listItemElements.length)) * index,
						duration,
						fill: 'forwards',
					});
				});

				this.switchLanguageElement.animate([
					{ opacity: '1', translate: '0 0' },
					{ opacity: '0', translate: '0 calc(5rem / 10)' },
				], {
					delay: 0,
					duration,
					fill: 'forwards',
				});
			}

			this.headerBackdropElement.toggleAttribute('data-visible', false);

			this.buttonHamburgerElement.toggleAttribute('data-active', false);

			this.#fadeOutAnimation.addEventListener('finish', () => {
				this.containerElement.toggleAttribute('data-open', false);
				[...this.elementsToInert].forEach((_) => _.toggleAttribute('inert', false));
				this.#isOpen = false;
				if (instant === false) {
					this.buttonHamburgerElement.focus();
				}
			});
		}
	}
}

const element = document.querySelector('.m-navigation');
if (element) {
	document.mNavigation = new MNavigation(element);
}
