import { LitElement, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';

/** @typedef {import("./vi-cart").default} ViCart */

const translations = JSON.parse(document.getElementById('api-translations')?.innerText ?? '{}');

class ViProductListItem extends LitElement {
	static properties = {
		readonly: { type: Boolean },
		loading: { type: Boolean },
		id: { type: String },
		key: { type: String },
		productImage: { type: Object },
		url: { type: String },
		productTitle: { type: String },
		subtitle: { type: String },
		articleNumber: { type: String },
		color: { type: String },
		size: { type: String },
		price: { type: String },
		sum: { type: String },
		quantity: { type: Number },
		length: { type: String },
		option: { type: String },
		maxAmount: { type: Number },
		system: { type: Object },
		packageContents: { type: String },
		parameters: { type: Array },
		data: { type: Object },
		variant: { type: String },
	};

	constructor() {
		super();
		this.readonly = false;
		this.loading = false;
		this.id = '';
		this.key = '';
		this.productImage = {};
		this.url = '';
		this.productTitle = '';
		this.subtitle = '';
		this.articleNumber = '';
		this.color = '';
		this.size = '';
		this.sum = '';
		this.quantity = 1;
		this.maxAmount = 100;
		this.system = null;
		this.packageContents = '';
		this.parameters = null;
		this.data = {};
		this.variant = null;
	}

	get quantityOptions() {
		return Array.from({ length: this.maxAmount }, (_, i) => i + 1).map((i) => ({
			value: i,
			text: i,
		}));
	}

	render() {
		return html`
			<link rel="stylesheet" href="/assets/css/vi-product-list-item.${BUILT_AT}.css">
			<div class="container">
				${this.productImage ? html`
					${this.url ? html`
						<a class="image" href=${this.url} tabindex="-1">
							<img sizes="72px" srcset=${this.productImage.srcset} src=${this.productImage.src} width=${this.productImage.width} height=${this.productImage.height} alt=${this.productImage.alt} loading=${this.productImage.loading}>
						</a>
					` : html`
						<div class="image">
							<img sizes="72px" srcset=${this.productImage.srcset} src=${this.productImage.src} width=${this.productImage.width} height=${this.productImage.height} alt=${this.productImage.alt} loading=${this.productImage.loading}>
						</div>
					`}
				` : null}
				${this.url ? html`
					<a href="${this.url}" class="title">
						<strong>${this.productTitle}</strong>
						<span>${this.subtitle}</span>
					</a>
				` : html`
					<div class="title">
						<strong>${this.productTitle}</strong>
						<span>${this.subtitle}</span>
					</div>
				`}
				<table class="table">
					${this.articleNumber ? html`<tr><th>${translations['product.articleNumber']}</th><td>${this.articleNumber}</td></tr>` : null}
					${this.system ? html`<tr><th>${translations['product.system']}</th><td>
						<a href="${this.system.url}" class="m-card-system" data-size="xxx-small">
							<div class="m-card-system__container">
								<img srcset="${this.system.image.srcset}" alt="">
								<div class="m-card-system__text">
									<strong class="m-card-system__title">
										${this.system.title}
									</strong>
								</div>
							</div>
						</a>
					</td></tr>` : null}
					${this.parameters?.map((parameter) => html`<tr><th>${parameter.key}</th><td>${parameter.value}</td></tr>`)}
					${this.color ? html`<tr><th>${translations['product.color']}</th><td>${this.color}</td></tr>` : null}
					${this.size && !this.length ? html`<tr><th>${translations.size}</th><td>${this.size}</td></tr>` : null}
					${this.length ? html`<tr><th>${translations['product.length']}</th><td>${this.length}</td></tr>` : null}
					${this.option ? html`<tr><th>${this.option.name}</th><td>${this.option.value}</td></tr>` : null}
					${this.packageContents ? html`<tr class="package-contents"><th>${translations['product.package-contents']}</th><td>${unsafeHTML(this.packageContents)}</td></tr>` : null}
				</table>
				<div class="price">
					${this.loading ? unsafeHTML(icons.loader) : html`${this.sum !== null ? this.sum : translations['product.priceUponRequest']}`}
				</div>
				<div class="quantity">${this.quantity} × ${this.price}</div>
				${!this.readonly ? html`
					<div class="options">
						<select
							class="a-select"
							name="quantity"
							data-size="small"
							aria-label="${translations['product.amount']}"
							@change=${this.handleQuantityChange}
						>
							${this.quantityOptions?.map((item) => html`<option value=${item.value} ?selected=${item.value === this.quantity}>${item.text}</option>`)}
						</select>
						<div class="options-buttons">
							${this.variant === 'wishlist' && this.price ? html`
								<vi-tooltip id="tooltip-wishlist-item-${this.key}" hidden>${translations['product.add-to-cart']}</vi-tooltip>
								<button data-tooltip-target="tooltip-wishlist-item-${this.key}" class="a-button" data-kind="tertiary" data-shape="squared" data-size="small" @click=${this.addToCart}>${unsafeHTML(icons['cart-add'])}</button>
							` : null}
							<button class="a-button" data-kind="tertiary" data-size="small" @click=${this.emitRemove}>${translations['wishlist.remove-product']}</button>
						</div>
					</div>
				` : null}
			</div>
		`;
	}

	handleQuantityChange(event) {
		this.quantity = parseFloat(event.target.value);
		this.emitUpdate();
	}

	emitUpdate() {
		this.dispatchEvent(new CustomEvent('update'));
	}

	emitRemove() {
		this.dispatchEvent(new CustomEvent('remove'));
	}

	async addToCart() {
		const {
			id,
			quantity,
			currency,
			data,
		} = this;

		/** @type {ViCart} */
		const cartElement = document.querySelector('vi-cart');

		/** Cart Response data */
		await cartElement.addItem(id, quantity, currency, data);

		this.dispatchEvent(new CustomEvent('addedToCart'));
	}
}

// Define the custom element
if (!customElements.get('vi-product-list-item')) {
	customElements.define('vi-product-list-item', ViProductListItem);
}
